<template>
  <div class="service">
    <div class="panel">
      <div class="img">
        <img
          :src="require('/src/assets/img/service/ST.png')"
          class="mybutton"
          @click="toRouter('/service/smart-traffic')"
        />
      </div>
      <span class="content"
        >- Road Sensing <br />
        - V2X
      </span>
    </div>
    <div class="panel" style="justify-content: flex-end">
      <span class="content"
        >- District panoramic digital system <br />- Algorithm Training <br />-
        Implementation/Safety Verification
      </span>
      <div class="img">
        <img
          class="mybutton"
          :src="require('/src/assets/img/service/SC.png')"
          @click="toRouter('/service/auto-vehicle')"
        />
      </div>
    </div>
    <div class="panel">
      <div class="img">
        <img
          class="mybutton"
          :src="require('/src/assets/img/service/GE.png')"
          @click="toRouter('/service/green-energy-ev')"
        />
      </div>
      <span class="content"
        >- EV Charging Piles <br />- EVC Operating System
      </span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toRouter(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.service {
  display: flex;
  width: 100%;
  height: 100%;
  background: url("../../assets/img/service.png") center center;
  background-size: cover;
  font-size: 3.33vh;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 4.65vh;
  letter-spacing: 0.417vh;
  .panel {
    flex: 1;
    padding: 3.24vh 1.92vw;
    display: flex;
    flex-direction: column;

    .img {
      width: 100%;

      > img {
        width: 100%;
        height: 28.5vh;
      }
    }
    .content {
      margin-left: 44px;
    }
  }
}
</style>
